import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Constants } from "../../../../constants/constants";
import { AzureAccountsService } from "../azure-accounts.service";
import { AzureAccount } from "../../../../models/shared";
import { TitleService } from "../../../../services/title.service";
import { take } from "rxjs/operators";

@Component({
    selector: "app-azure-account-form",
    templateUrl: "./azure-account-form.component.html"
})
export class AzureAccountFormComponent implements OnInit, OnDestroy {
    azureAccount: AzureAccount;
    azureAccountNames: string[];
    accountId: number;
    action: string;

    submitted = false;
    minLength = 2;
    saving = false;
    isEdit: boolean;
    loading = true;
    constants = Constants;

    private azureAccountsSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private ts: AzureAccountsService,
        private titleService: TitleService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.accountId = parseInt(params.get("id"), 10);
            this.action = params.get("action");
            if (this.accountId != null) {
                this.ts.refreshAzureAccounts();

                this.ts.azureAccounts.pipe(take(1)).subscribe(azureAccounts => {
                    this.azureAccount = Object.assign(
                        {},
                        azureAccounts.find(t => t.id === this.accountId)
                    );
                    this.prepForm();
                    this.loading = false;
                });
            } else {
                this.prepForm();
                this.loading = false;
            }
        });
    }

    prepForm() {
        if (this.action) {
            if (this.action === "edit") this.isEdit = true;
        }

        if (!this.azureAccount && !this.isEdit) {
            this.azureAccount = new AzureAccount();
            this.azureAccount.name = "";
        }

        // Set Title
        this.titleService.setTitle("AZURE_ACCOUNT", this.action, this.azureAccount);
    }

    ngOnInit() {
        this.ts.refreshAzureAccounts();

        this.azureAccountsSubscription = this.ts.azureAccounts.subscribe((azureAccounts: AzureAccount[]) => {
            if (this.isEdit)
                this.azureAccountNames = azureAccounts.filter(t => t.id !== this.accountId).map(t => t.name);
            else this.azureAccountNames = azureAccounts.map(t => t.name);
        });
    }

    ngOnDestroy() {
        if (this.azureAccountsSubscription) this.azureAccountsSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.azureAccount.name,
            client_id:
                !this.isEdit || (this.azureAccount.client_id && this.azureAccount.client_id.length > 0)
                    ? this.azureAccount.client_id
                    : undefined,
            secret:
                !this.isEdit || (this.azureAccount.secret && this.azureAccount.secret.length > 0)
                    ? this.azureAccount.secret
                    : undefined,
            domain: !this.isEdit ? this.azureAccount.domain : undefined,
            subscription_id: !this.isEdit ? this.azureAccount.subscription_id : undefined
        };

        const result = this.isEdit
            ? await this.ts.updateAzureAccount({ params: { id: this.accountId }, body: model })
            : await this.ts.addAzureAccount({ body: model });

        this.saving = false;

        if (result !== false) {
            this.router.navigate([Constants.urls.configuration.azure]);
        }
    }

    cancel() {
        this.router.navigate([Constants.urls.configuration.azure]);
    }
}
